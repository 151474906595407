import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePageName } from "react-page-name";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import axios from "axios";
import "./viewTeam.scss";

const ViewTeam = () => {
    const { t, i18n } = useTranslation();

    const [team, setTeam] = useState(null); // Initialize as null
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "de" ? "" : i18n.language + "/";

    const location = useLocation();
    const teamSlug = location.pathname.split("/")[3];

    useEffect(() => {
        const fetchAllRef = async () => {
            try {
                // Fetch für Team
                const teamRes = await axios.get(`https://studiog.2ix.de/wp-json/wp/v2/team?slug=${teamSlug}`);
                if (teamRes.data && teamRes.data.length > 0) {
                    setTeam(teamRes.data[0]); // Set to first item if array is returned
                } else {
                    setTeam(null); // Set to null if no data
                }
            } catch (err) {
                console.log(err);
                setTeam(null); // Set to null in case of error
            } finally {
                setLoading(false); // Ensure loading is stopped in all cases
            }
        };

        fetchAllRef();
    }, [lang, teamSlug]);

    usePageName(isLoading ? `${t("site.loading")} | ${t("site.pageName")}` : `${team.acf.titel} ${team.title.rendered || ''} | ${t("site.pageName")}`);

    return (
        <div className="viewTeam">
            {isLoading ? (
                <div className="loading" />
            ) : (
                <div className="content">
                    <div className="header" style={{ background: `rgba(${team.acf.bereich}, 0.7)` }}>
                        <span className="titel">{team.acf.titel}</span>
                        <span className="name" style={{ color: `rgba(${team.acf.bereich})` }}>{team.title.rendered}</span>
                    </div>
                    <div className="container">
                        {team.acf.email &&
                            <Link to={`mailto:${team.acf.email}`} className="mailLink" style={{color: `rgb(${team.acf.bereich})`}}>
                                <span className="email">{team.acf.email}</span>
                                <span className="label">E-Mail</span>
                            </Link>
                        }

                        <h4 style={{color: `rgb(${team.acf.bereich})`}}>{team.acf.funktion}</h4>
                        <h2>{t("team.tätigkeitenBeiStudioG")}</h2>
                        <div className="wp-content" dangerouslySetInnerHTML={{ __html: team.content.rendered }} style={{color: `rgb(${team.acf.bereich})`}} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ViewTeam;
