import React, { useEffect, useState } from "react";
import { usePageName } from "react-page-name";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import axios from "axios";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./home.scss"

const Home = () => {
    const { t, i18n } = useTranslation();

    usePageName(t("site.pageName"));

    const [header, setHeader] = useState([]);
    const [leistungen, setLeistungen] = useState([]);
    const [news, setNews] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "it" ? "it/" : "";

    const CustomPrevArrow = (props) => {
        const { onClick } = props;
        return (
            <div className="arrow prev" onClick={onClick}>
                <ArrowBackOutlinedIcon />
            </div>
        );
    };

    const CustomNextArrow = (props) => {
        const { onClick } = props;
        return (
            <div className="arrow next" onClick={onClick}>
                <ArrowForwardOutlinedIcon />
            </div>
        );
    };

    const sliderSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />
    };

    useEffect(() => {

        const fetchData = async () => {
            try {
                // Fetch für Header
                const headerRes = await axios.get(`https://studiog.2ix.de/wp-json/wp/v2/header`);
                if (headerRes.data && headerRes.data.length > 0) {
                    setHeader(headerRes.data);
                }

                // Fetch für Leistungen
                const leistungenRes = await axios.get(`https://studiog.2ix.de/wp-json/wp/v2/dienstleistungen`);
                if (leistungenRes.data && leistungenRes.data.length > 0) {
                    setLeistungen(leistungenRes.data);
                }

                // Fetch für News
                const newsRes = await axios.get(`https://studiog.2ix.de/wp-json/wp/v2/news`);
                if (newsRes.data && newsRes.data.length > 0) {
                    setNews(newsRes.data);
                }

                setLoading(false);
            } catch (err) {
                console.error(err);
            }
        };

        fetchData();
    }, [lang]);

    return (
        <div className="home">
            <div className="header">
                <div className="headerTop">
                    <div className="popUp">
                        <div className="left">
                            <Link to="tel:+390474411324">+39 0474 411 324</Link>
                            <Link to="mailto:info@studiog.it">info@studiog.it</Link>
                        </div>
                        <div className="right">
                            <TextsmsOutlinedIcon />
                        </div>
                    </div>
                    <div className="slideshow">
                        {isLoading ? (
                            <div className="laden"></div>
                        ) : (
                            <>
                                <Slider {...sliderSettings}>
                                    {header.map((item, index) => (
                                        <div key={index}>
                                            {item.acf.image.endsWith(".mp4") ? (
                                                <video muted loop autoPlay src={item.acf.image} />
                                            ) : (
                                                <img src={item.acf.image} alt="Hintergrundbild" />
                                            )}
                                        </div>
                                    ))}
                                </Slider>
                            </>
                        )}
                    </div>
                </div>
                <div className="headerBottom">
                    <div className="top">
                        <button>
                            <ExpandMoreOutlinedIcon />
                        </button>
                        <Link>Studio G</Link>
                    </div>
                    <div className="bottom">
                        <h1>{t("home.headerText")}</h1>
                        <p>{t("home.textStart")}</p>
                    </div>
                </div>
            </div>
            <div className="leistungen">
                <div className="container">
                    <h4>{t("site.dienstleistungen")}</h4>
                    <h1>{t("dienstleistungen.unsereDienstleistungspalette")}</h1>
                    <div className="grid">
                        {isLoading ? <div className='loading' /> : leistungen.map(item => (
                            <Link key={item.id} className="item" to={`/${i18n.language}/dienstleistungen/${item.slug}`}>
                                <div className="box">
                                    <div className="top">
                                        <span>{item.title.rendered}</span>
                                        <ArrowForwardOutlinedIcon />
                                    </div>
                                    <div className="bottom">
                                        <span>{t("btn.weitereInfos")}</span>
                                    </div>
                                </div>
                                <img
                                    className="image"
                                    src={item.acf.image || "/assets/placeholder.png"}
                                    alt={item.title.rendered}
                                />
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
            <div className="team">
                <div className="container">
                    <div className="left">
                        <h4>{t("site.team")}</h4>
                        <h1>{t("home.teamH")}</h1>
                        <p>{t("home.teamP")}</p>
                        <Link to={`/${i18n.language}/team`} className="button">{t("team.zumTeam")}</Link>
                    </div>
                    <img src="https://www.studiog.it/assets/img/studiog_team_1-1.jpg" alt="Team Studio G" />
                </div>
            </div>
            <div className="news">
                <div className="container">
                    <h4>{t("news.subTitle")}</h4>
                    <h1>{t("news.heading")}</h1>
                    <div className="grid">
                        {isLoading ? <div className='loading' /> : news.map(item => (
                            <Link key={item.id} className="item" to={`/${i18n.language}/news/${item.slug}`}>
                                <div className="box">
                                    <div className="top">
                                        <span>{item.title.rendered}</span>
                                        <ArrowForwardOutlinedIcon />
                                    </div>
                                    <div className="bottom">
                                        <span>{t("btn.zumProjekt")}</span>
                                    </div>
                                </div>
                                <img
                                    className="image"
                                    src={item.acf.image || "/assets/placeholder.png"}
                                    alt={item.title.rendered}
                                />
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Home